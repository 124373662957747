<template>
  <v-dialog v-model="dialog" width="600px" persistent>
    <v-card class="border-radius">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon small>close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-text
        v-if="!newUser.email && !user.id"
        class="text-xs-center pt-0"
      >
        <div class="mb-5 title font-weight-medium">
          {{ $t("addNewUserHeadLIne") }}
        </div>
        <v-form class="mx-5 mb-5" @submit.prevent="findUser(email)">
          <v-text-field
            v-model="email"
            v-validate.disable="'required|email'"
            :error-messages="errors.collect('email')"
            :data-vv-as="$tc('email', 2)"
            :data-vv-name="'email'"
            :label="$tc('email', 1)"
            :disabled="isLoading"
          ></v-text-field>
        </v-form>
        <div class="text-center">
          <v-btn
            round
            class="white sw-primary text-none"
            @click="closeDialog"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            round
            class="sw-accent-bg sw-on-accent text-none"
            @click="findUser(email)"
            :loading="isLoading"
            >{{ $t("continue") }}</v-btn
          >
        </div>
      </v-card-text>
      <v-card-text v-if="newUser.email" class="pt-0">
        <div class="mb-5 title font-weight-medium text-center">
          {{ $t("createNewUserHeadLIne") }}
        </div>
        <v-layout class="row wrap mb-5">
          <v-flex class="xs6 pr-2">
            <v-text-field
              v-model="newUser.first_name"
              :error-messages="errors.collect('first_name')"
              v-validate.disable="'required'"
              data-vv-name="first_name"
              :data-vv-as="$tc('first-name', 2)"
              :label="$tc('first-name', 1)"
              :disabled="isLoading"
            ></v-text-field>
          </v-flex>
          <v-flex class="xs6 pl-2">
            <v-text-field
              v-model="newUser.last_name"
              :error-messages="errors.collect('last_name')"
              v-validate.disable="'required'"
              data-vv-name="last_name"
              :data-vv-as="$tc('last-name', 2)"
              :label="$tc('last-name', 1)"
              :disabled="isLoading"
            ></v-text-field>
          </v-flex>
          <v-flex class="xs12">
            <v-text-field
              v-model="newUser.email"
              :error-messages="errors.collect('email')"
              v-validate.disable="'required|email'"
              data-vv-name="email"
              :data-vv-as="$tc('email', 2)"
              :label="$tc('email', 1)"
              disabled
            ></v-text-field>
          </v-flex>
          <v-flex class="xs6 pr-2">
            <CountryCodeSelector
              v-model="newUser.phone_code"
              v-validate.disable="newUser.phone ? 'required' : ''"
              data-vv-name="phone_code"
              :data-vv-as="$t('common.phone_code')"
              :label="''"
              :attatched="false"
              :disabled="isLoading"
            />
            <ErrorMessages :error-messages="errors.collect('phone_code')" />
          </v-flex>
          <v-flex class="xs6 pl-2">
            <v-text-field
              v-model="newUser.phone"
              v-validate.disable="phoneNumberRules"
              :error-messages="errors.collect('phone')"
              :data-vv-name="'phone'"
              :data-vv-as="$tc('phone', 2)"
              :label="$tc('phone', 1)"
              :disabled="isLoading"
            ></v-text-field>
          </v-flex>
          <v-flex class="xs12">
            <v-checkbox
              v-model="termsAccepted"
              :label="$t('group_user_list_page.terms_accepted_at_label')"
              :disabled="isLoading"
              hide-details
            >
            </v-checkbox>
          </v-flex>
        </v-layout>
        <div class="text-xs-center">
          <v-btn
            round
            class="sw-secondary-bg sw-on-secondary text-none"
            :loading="isLoading"
            @click="createUser()"
            >{{ $t("common.save_and_close") }}</v-btn
          >
          <v-btn
            round
            class="sw-secondary-bg sw-on-secondary text-none"
            :loading="isLoading"
            @click="createUser('edit')"
            >{{ $t("common.save_and_edit") }}</v-btn
          >
        </div>
      </v-card-text>
      <v-card-text v-if="user.id" class="pt-0">
        <div class="mb-5">
          <div class="mb-5 title font-weight-medium text-xs-center">
            {{ $t("subscribeNewUserHeadLIne") }}
          </div>
          <div class="mx-5 mb-1 theme-primary font-weight-medium">
            {{ user.first_name }} {{ user.last_name }}
          </div>
          <div class="mx-5 mb-1 theme-primary font-weight-medium">
            {{ user.email }}
          </div>
          <div
            v-if="user.phone_code && user.phone"
            class="mx-5 theme-primary font-weight-medium"
          >
            +{{ user.phone_code }} {{ user.phone }}
          </div>
        </div>
        <div class="text-xs-center">
          <v-btn
            round
            class="sw-secondary-bg sw-on-secondary text-none"
            @click="subscribeUser(user)"
            :loading="isLoading"
            >{{ $t("subscribeBtn") }}</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CountryCodeSelector from "@/components/CustomFields/CountryCodeSelector.vue";
import moment from "moment-timezone";

export default {
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    isLoading: false,
    email: "",
    user: {},
    newUser: {
      email: "",
      phone_code: "45",
    },
    termsAccepted: false,
  }),
  computed: {
    phoneNumberRules() {
      return process.env.VUE_APP_PHONE_NUMBER_RULES || "numeric|min:8|max:12";
    },
  },
  components: {
    CountryCodeSelector,
  },
  methods: {
    async findUser(email) {
      const valid = await this.$validator.validate();

      if (!valid) return;

      this.isLoading = true;

      const params = {
        email: email,
      };

      this.$api.users.search(params).then(
        (response) => {
          if (!response) return;

          if (!response.data.data.length) {
            this.isLoading = false;
            this.newUser.email = this.email;
            return;
          }

          this.findUserInGroup(response.data.data[0]);
        },
        () => {
          this.isLoading = false;
        },
      );
    },
    findUserInGroup(model) {
      if (!model) return;

      this.$api.groupUsers.get(this.groupId, model.id).then(
        (response) => {
          this.isLoading = false;

          if (!response) return;

          this.errors.add({
            field: "email",
            msg: this.$t("userAlreadySubscribed"),
          });
        },
        () => {
          this.isLoading = false;
          this.user = model;
        },
      );
    },
    async subscribeUserSilent(model) {
      const params = [this.groupId, model.id];

      await this.$api.groupUsers.subscribe(...params);
    },
    async subscribeUser(model) {
      if (!model) return;

      try {
        this.isLoading = true;

        await this.subscribeUserSilent(model);

        this.isLoading = false;

        this.$store.dispatch("addNotification", {
          message: this.$t("userSubscribed", {
            name: `${model.first_name} ${model.last_name}`,
          }),
        });

        this.$emit("success");
        this.closeDialog();
      } catch (error) {
        this.isLoading = false;

        if (error) {
          this.errorMessageShow(error);
        }
      }
    },
    async createUser(action) {
      const valid = await this.$validator.validate();

      if (!valid) return;

      try {
        this.isLoading = true;

        const specs = {
          first_name: this.newUser.first_name,
          last_name: this.newUser.last_name,
          email: this.newUser.email,
          phone_code: this.newUser.phone ? this.newUser.phone_code : "",
          phone: this.newUser.phone,
          password: Math.random().toString(36).substr(2, 8),
          terms_accepted_at: this.termsAccepted
            ? moment().format("YYYY-MM-DDTHH:mm:ssZ")
            : null,
        };

        const response = await this.$api.users.create(specs);

        await this.subscribeUserSilent(response.data.data);

        this.isLoading = false;

        this.$store.dispatch("addNotification", {
          message: this.$t("userSubscribed", {
            name: `${this.newUser.first_name} ${this.newUser.last_name}`,
          }),
        });

        this.$emit("success");
        this.closeDialog();

        if (action === "edit") {
          this.$router.push({
            name: "group_users_edit",
            params: {
              group_id: this.groupId,
              user_id: response.data.data.id,
            },
          });
        }
      } catch (error) {
        this.isLoading = false;

        if (error) {
          this.errorMessageShow(error);
        }
      }
    },
    openDialog() {
      this.errors.clear();

      this.isLoading = false;

      this.email = "";
      this.user = {};
      this.newUser = {
        email: "",
        phone_code: "45",
      };

      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>
